import React from "react";

const Sample = () => {
  return (
    <div>
      <h1>Sample page</h1>
    </div>
  );
};

export default Sample;
